import React, { useState } from 'react';
import logo from './img/logo.png';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  useScrollTrigger,
  Slide,
  Link,
  Button,
  Container
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  appbar: {
    opacity: '0.9',
    height: '90px'
  },
  toolbar: {
    height: '90px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  logo: {
    height: '90px',
    width: '90px'
  },
  linksDiv: {
    marginRight: '50px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '20px'
    },
    display: 'flex',
    alignItems: 'center'
  },
  link: {
    padding: '0 15px',
    margin: '0 15px',
    fontSize: '17px'
  }
}));

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
};

const Navbar = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const [top, setTop] = useState(true);

  window.addEventListener('scroll', (e) => {
    if (window.pageYOffset > 1) {
      setTop(false);
    } else {
      setTop(true);
    }
  });

  return (
    <div>
      <HideOnScroll {...props}>
        <AppBar
          color={top ? 'transparent' : 'primary'}
          className={classes.appbar}
        >
          <Container maxWidth='xl'>
            <Toolbar className={classes.toolbar}>
              <div className={classes.logoDiv}>
                <Link href='/' color='inherit'>
                  <img src={logo} alt='logo.png' className={classes.logo} />
                </Link>
              </div>
              <div className={classes.linksDiv}>
                <Button
                  href='/kontakt-anfahrt'
                  color='primary'
                  variant='contained'
                  className={classes.link}
                  style={{ backgroundColor: theme.palette.primary.light }}
                >
                  Kontakt & Anfahrt
                </Button>
                <Button
                  href='/galerie'
                  color='primary'
                  variant='contained'
                  className={classes.link}
                  style={{ backgroundColor: theme.palette.primary.light }}
                >
                  Galerie
                </Button>
                <Button
                  href='/buchen'
                  color='secondary'
                  variant='contained'
                  className={classes.link}
                >
                  Buchen
                </Button>
              </div>
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>
    </div>
  );
};

export default Navbar;
